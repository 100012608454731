import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import LastPageIcon from '@mui/icons-material/LastPage'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import {
  Autocomplete,
  Box,
  Drawer,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'

import { EventCard } from '@components/EventCard'
import type { EventType } from '@models/Event'
import { eventTitleMap, getAvailableTypes } from '@util/log-util'

import type { RootState } from '../../../store'
import { getProjectEvents } from '../state'

interface Props {
  onClose: () => void
  open: boolean
}

export const Log = ({ onClose, open }: Props) => {
  const { events } = useSelector((state: RootState) => ({
    events: getProjectEvents(state),
  }))

  const [showTypes, setShowTypes] = useState<EventType[]>([])
  const [expandAll, setExpandAll] = useState(true)
  const availableTypesArr = getAvailableTypes(events)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      data-testid="project-log-drawer"
    >
      <Box p={2}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '500px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
          >
            <IconButton
              data-testid="close-button"
              onClick={onClose}
              sx={{ color: 'black' }}
            >
              <LastPageIcon />
            </IconButton>

            <ToggleButtonGroup
              value={expandAll}
              exclusive
              onChange={(_, bool) => setExpandAll(bool)}
            >
              <Tooltip title="Alles uitvouwen">
                <ToggleButton
                  value={true}
                  selected={expandAll}
                  data-testid="expand-all-button"
                >
                  <UnfoldMoreIcon />
                </ToggleButton>
              </Tooltip>

              <Tooltip title="Alles inklappen">
                <ToggleButton
                  value={false}
                  selected={!expandAll}
                  data-testid="collapse-all-button"
                >
                  <UnfoldLessIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={availableTypesArr}
              getOptionLabel={(option) => eventTitleMap[option]}
              onChange={(_, types) => {
                if (!types.length) {
                  setExpandAll(true)
                }

                setShowTypes(types)
              }}
              filterSelectedOptions
              value={showTypes}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Type" />
              )}
            />
          </Grid>

          {events.length > 0 && (
            <Grid item xs={12}>
              {events
                .filter((event) => {
                  return showTypes.length === 0
                    ? true
                    : showTypes.includes(event.type)
                })
                .map((event) => (
                  <EventCard key={event._id} event={event} open={expandAll} />
                ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  )
}
