import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { Event, SaveEvent } from '@models/Event'

import { apiError, success } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'
import { storeProject, updateMyList } from '../projecten/state'

import type { OptionsDialogData, State } from './types'

const initialState: State = {
  dialog: {
    options: {
      open: false,
      data: {
        project: undefined,
      },
    },
  },
  project: undefined,
  status: 'idle',
  data: {
    events: [],
  },
  saveEventStatus: 'idle',
}

export const fetchProject = createAsyncThunk(
  'fetch/project',
  async (id: string, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/project/${id}`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van project', error))

      throw error
    }
  },
)

export const fetchLog = createAsyncThunk<Event[], any>(
  'fetch/aansluiting/events',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const result = await request<Event[]>({
        url: `/rest/project/${id}/event`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van project log', error))

      return rejectWithValue({ error })
    }
  },
)

export const saveEvent = createAsyncThunk(
  'save/event',
  async (data: SaveEvent, { dispatch, rejectWithValue }) => {
    const { id } = data

    try {
      const result = await request({
        url: `/rest/project/${id}/event`,
        method: 'POST',
        data,
      })

      dispatch(fetchProject(id))
      dispatch(storeProject(result))

      dispatch(closeProjectOptionsDialog())
      dispatch(success('Wijziging succesvol opgeslagen'))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij opslaan wijziging', error))

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    openProjectOptionsDialog: (
      state,
      action: PayloadAction<OptionsDialogData>,
    ) => {
      state.dialog.options.open = true
      state.dialog.options.data = action.payload
    },
    closeProjectOptionsDialog: (state) => {
      state.dialog.options.open = false
      state.dialog.options.data = initialState.dialog.options.data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, (state) => {
        state.status = 'loading'
        state.project = initialState.project
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.status = 'idle'
        state.project = action.payload
      })
      .addCase(fetchProject.rejected, (state) => {
        state.status = 'error'
        state.project = initialState.project
      })
      .addCase(fetchLog.pending, (state) => {
        state.data.events = initialState.data.events
      })
      .addCase(fetchLog.fulfilled, (state, action) => {
        state.data.events = action.payload
      })
      .addCase(fetchLog.rejected, (state) => {
        state.data.events = initialState.data.events
      })
      .addCase(updateMyList.fulfilled, (state, action) => {
        const { projecten } = action.payload

        if (state.project) {
          state.project.myList = projecten.includes(state.project.aanvraagID)
        }
      })
      .addCase(saveEvent.fulfilled, (state) => {
        state.saveEventStatus = 'idle'
      })
      .addCase(saveEvent.pending, (state) => {
        state.saveEventStatus = 'loading'
      })
      .addCase(saveEvent.rejected, (state) => {
        state.saveEventStatus = 'error'
      })
  },
})

export const getProject = (state: RootState) => ({
  project: state.project.project,
  isError: state.project.status === 'error',
})
export const getProjectEvents = (state: RootState) =>
  state.project.data.events.filter(
    (event) => event.type !== 'product-annulering',
  )

export const getProjectOptionsDialogOpen = (state: RootState) =>
  state.project.dialog.options.open
export const getProjectOptionsDialogData = (state: RootState) =>
  state.project.dialog.options.data

export const isSaveEventPending = (state: RootState) =>
  state.project.saveEventStatus === 'loading'

export const { reducer } = slice
export const { openProjectOptionsDialog, closeProjectOptionsDialog } =
  slice.actions
