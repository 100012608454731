import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import StyledTable from '@components/StyledTable'
import useLocalStorage from '@hooks/useLocalStorage'
import type { Page, Sort } from '@models/Query'
import { fetchAutosuggest, getAutosuggest } from '@slices/autosuggestSlice'

import { ProjectOptions } from '../project/components/options/ProjectOptions'

import { Filters } from './components/Filters'
import { Row } from './components/Row'
import {
  fetchHoofdleidingProjecten,
  getHoofdleidingProjecten,
  initialState,
} from './state'
import type { HoofdleidingProject } from './types'

const headerCells = [
  { id: 'status', numeric: false, label: '', sort: false, fixed: 10 },
  { id: 'naam', numeric: false, label: 'Project' },
  { id: 'externeReferentie', numeric: false, label: 'Referenties' },
  { id: 'adres', numeric: false, label: 'Adres', fixed: 300 },
  { id: 'gebied', numeric: false, label: 'Type en gebied' },
  { id: 'disciplines', numeric: false, label: 'Disciplines', fixed: 160 },
  { id: 'dueWeek', numeric: true, label: 'Opleverweek werkmap' },
  {
    id: 'startUitvoeringWeek',
    numeric: false,
    label: 'Geplande uitvoeringsweek',
    sort: true,
  },
  { id: 'statusText', numeric: false, label: 'Processtap' },
  { id: 'bijlagen', numeric: false, label: 'Mijlpalen', fixed: 120 },
  { id: 'actions', numeric: false, label: '', fixed: 150 },
] as const

export const Projecten: FC = () => {
  const dispatch = useDispatch()
  const { data, isLoading, query } = useSelector(getHoofdleidingProjecten)
  const [selectedProject, setSelectedProject] = useState<HoofdleidingProject>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>()
  const options = useSelector(getAutosuggest)

  const { items: hoofdleidingen, count, skip, limit } = data

  const { sort, page } = query

  const { item: storedFilters } = useLocalStorage(
    'hoofdleidingen',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)
  const [localPage, setLocalPage] = useState(page)
  const [localSort, setLocalSort] = useState(sort)

  const onChangeFilters = (newFilters) => {
    setLocalSort(initialState.query.sort)
    setLocalPage(initialState.query.page)
    setLocalFilters({ ...newFilters })
  }

  const onChangeSort = (sort: Sort) => setLocalSort(sort)
  const onChangePage = (page: Page) => setLocalPage(page)

  useEffect(() => {
    dispatch(
      fetchHoofdleidingProjecten({
        filters: localFilters,
        sort: localSort,
        page: localPage,
      }),
    )
  }, [dispatch, localFilters, localPage, localSort])

  const onClickMenu = useCallback((anchorEl, project) => {
    setSelectedProject(project)
    setAnchorEl(anchorEl)
  }, [])

  const onSearchChange = useCallback(
    (search) => {
      dispatch(fetchAutosuggest({ text: search, isHoofdleiding: true }))
    },
    [dispatch],
  )

  return (
    <Box sx={{ width: 0.95, m: 'auto' }}>
      <Typography variant="h2">Overzicht van uw projecten</Typography>

      <Paper sx={{ mb: 2 }}>
        <Filters
          filters={localFilters}
          initialFilters={initialState.query.filters}
          onChange={onChangeFilters}
          options={options}
          onSearchChange={onSearchChange}
        />
        <StyledTable
          onChangeSort={onChangeSort}
          onChangePage={onChangePage}
          headerCells={headerCells}
          sort={sort}
          stats={{ count, skip, limit }}
          initialSort={initialState.query.sort}
          loading={isLoading}
        >
          {hoofdleidingen.map((project) => (
            <Row
              key={project.aanvraagID}
              project={project}
              onClickMenu={onClickMenu}
            />
          ))}
        </StyledTable>
      </Paper>
      {selectedProject && (
        <ProjectOptions
          project={selectedProject}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </Box>
  )
}
