import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BusinessIcon from '@mui/icons-material/Business'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Typography } from '@mui/material'

import { MyListButton } from '@components/MyListButton'
import StyledCard from '@components/StyledCard'
import StyledIconButton from '@components/StyledIconButton'
import StyledIconLink from '@components/StyledIconLink'
import {
  canChangeProjectForProject,
  canCreateAssignedBijlageForProject,
  getPossibleAssigneesForProject,
  isUserVerantwoordelijkForProject,
} from '@util/project-util'

import Status from '../../aansluiting/project/components/Status'
import { getUser } from '../../auth/state'
import { Activiteiten } from '../../entity/activiteiten/components/Activiteiten'
import {
  ENTITY_PROJECT,
  clearEntityActiviteiten,
  fetchActiviteitenForEntity,
  getActiviteitenForEntity,
} from '../../entity/activiteiten/state'
import { AssignedBijlagenByProcesstap } from '../../entity/bijlagen/components/AssignedBijlagenByProcesstap'
import {
  clearEntityBijlagen,
  fetchAssignedBijlagenForEntity,
  fetchBijlageTemplatesForEntity,
  fetchBijlagenForEntity,
} from '../../entity/bijlagen/state'
import { LinkedProjects } from '../../entity/projecten/components/LinkedProjects'
import { clearEntityProjecten } from '../../entity/projecten/state'
import type { HoofdleidingProject } from '../../hoofdleiding/projecten/types'

import { Gegevens } from './components/Gegevens'
import { Log } from './components/Log'
import CreateEditProjectDialog from './components/dialogs/CreateEditProjectDialog'
import { ProjectOptions } from './components/options/ProjectOptions'
import {
  fetchHoofdleidingProject,
  fetchLog,
  getHoofdleidingProject,
  openProjectDialog,
} from './state'

export const Project: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    project: { isError, project },
    user,
    activiteiten,
  } = useSelector((state) => ({
    project: getHoofdleidingProject(state),
    user: getUser(state),
    activiteiten: getActiviteitenForEntity(state),
  }))

  const [showProjectLog, setShowProjectLog] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const { canViewActiviteiten } = user

  useEffect(() => {
    if (id) {
      dispatch(fetchHoofdleidingProject({ id }))
    }

    return () => {
      dispatch(clearEntityBijlagen())
      dispatch(clearEntityActiviteiten())
      dispatch(clearEntityProjecten())
    }
  }, [dispatch, id])

  useEffect(() => {
    if (!project) return

    const { aanvraagID } = project
    const entity = { id: aanvraagID, type: ENTITY_PROJECT }

    dispatch(fetchActiviteitenForEntity(entity))
    dispatch(fetchAssignedBijlagenForEntity(entity))
    dispatch(fetchBijlagenForEntity(entity))
    dispatch(fetchBijlageTemplatesForEntity(entity))
    dispatch(fetchLog(aanvraagID))
  }, [dispatch, project])

  const goBack = () => navigate(-1)
  const incorrectProjectType = project?.type !== 'Hoofdleiding'

  if (!project || isError || incorrectProjectType) {
    return (
      <Box sx={{ width: '95%', m: 'auto' }}>
        <Typography variant="h2">
          {isError || incorrectProjectType
            ? 'Project niet gevonden'
            : 'Project'}
        </Typography>

        <Button sx={{ ml: 3, color: 'rgb(188, 53, 141)' }} onClick={goBack}>
          <ArrowBackIcon sx={{ fontSize: '16px' }} />
          Terug
        </Button>
      </Box>
    )
  }

  const {
    aanvraagID,
    type,
    linkedProjectdossier,
    gebiedscode,
    gebiedscodeLabel,
    myList,
  } = project

  const entity = {
    id: aanvraagID,
    type: ENTITY_PROJECT,
    projecttype: 'Hoofdleiding',
  }

  const assignees = getPossibleAssigneesForProject({ project })
  const canChangeProject = canChangeProjectForProject({ project, user })
  const canCreateAssignedBijlage = canCreateAssignedBijlageForProject({
    project,
    user,
    assignees,
  })

  const isVerantwoordelijke = isUserVerantwoordelijkForProject({
    project,
    user,
  })

  const openEditProjectDialog = () => {
    dispatch(
      openProjectDialog({
        data: project as HoofdleidingProject,
        type: 'edit',
      }),
    )
  }

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">{`Project ${aanvraagID}`}</Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button sx={{ ml: 3, color: 'rgb(188, 53, 141)' }} onClick={goBack}>
          <ArrowBackIcon sx={{ fontSize: '16px' }} />
          Terug
        </Button>

        <MyListButton
          type="hoofdleiding"
          asButton
          sx={{ mr: 3 }}
          active={myList}
          aanvraagID={aanvraagID}
        />
      </Box>

      <StyledCard
        title="Gegevens"
        buttonBar={
          <>
            {linkedProjectdossier && (
              <StyledIconLink
                title={`Open projectdossier: ${linkedProjectdossier}`}
                href={`/projectdossier/${linkedProjectdossier}`}
                icon={<BusinessIcon />}
              />
            )}

            <Status project={project} placement={'right'} />

            {canChangeProject && (
              <StyledIconButton
                title="Wijzig project"
                onClick={openEditProjectDialog}
                icon={<EditIcon />}
              />
            )}

            <StyledIconButton
              title="Opties"
              onClick={(ev) => setAnchorEl(ev.currentTarget)}
              icon={<MoreVertIcon />}
            />

            <StyledIconButton
              title={'Project log'}
              onClick={() => setShowProjectLog(!showProjectLog)}
              icon={<InfoIcon />}
            />
          </>
        }
      >
        <Gegevens project={project} />
      </StyledCard>

      {canViewActiviteiten && (
        <StyledCard title="Activiteiten">
          <Activiteiten
            activiteiten={activiteiten}
            entity={{
              id: aanvraagID,
              type: ENTITY_PROJECT,
              gebiedscode,
              gebiedscodeLabel,
              projecttype: type,
            }}
            entityProps={project}
            assignees={assignees}
          />
        </StyledCard>
      )}

      <AssignedBijlagenByProcesstap
        user={user}
        entity={entity}
        assignees={assignees}
        showCreateAssignedBijlage={canCreateAssignedBijlage}
        projectStatus={project.statusText}
        isHoofdleidingProject
        isVerantwoordelijke={isVerantwoordelijke}
      />

      <LinkedProjects
        entity={entity}
        linkedProjectIds={project.linkedProjects}
        isVerantwoordelijke={isVerantwoordelijke}
      />

      <Log open={showProjectLog} onClose={() => setShowProjectLog(false)} />
      <ProjectOptions
        project={project}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <CreateEditProjectDialog />
    </Box>
  )
}
