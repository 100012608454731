import 'react-redux'

import { Action, configureStore } from '@reduxjs/toolkit'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'

import autosuggest from '@slices/autosuggestSlice'
import statusconfig from '@slices/statusconfigSlice'
import valuelists from '@slices/valuelistsSlice'

import { reducer as activiteiten } from './aansluiting/activiteiten/state'
import { reducer as assignedBijlagen } from './aansluiting/bijlagen/state'
import { reducer as dashboard } from './aansluiting/dashboard/state'
import { reducer as klant } from './aansluiting/klant/state'
import { reducer as project } from './aansluiting/project/state'
import { reducer as projecten } from './aansluiting/projecten/state'
import { reducer as alerts } from './alerts/state'
import { reducer as auth } from './auth/state'
import { reducer as beheer } from './beheer/state'
import { reducer as contract } from './contracten/state'
import { reducer as entityActiviteiten } from './entity/activiteiten/state'
import { reducer as entityBijlagen } from './entity/bijlagen/state'
import { reducer as entityProjecten } from './entity/projecten/state'
import { reducer as hoofdleidingActiviteiten } from './hoofdleiding/activiteiten/state'
import { reducer as hoofdleidingAssignedBijlagen } from './hoofdleiding/bijlagen/state'
import { reducer as hoofdleidingDashboard } from './hoofdleiding/dashboard/state'
import { reducer as hoofdleidingProject } from './hoofdleiding/project/state'
import { reducer as hoofdleidingProjecten } from './hoofdleiding/projecten/state'
import { reducer as combiOpdracht } from './opdracht/combi/state'
import { reducer as opdracht } from './opdracht/state'
import { reducer as combiOpdrachten } from './opdrachten/combi/state'
import { reducer as opdrachten } from './opdrachten/state'
import { reducer as projectdossier } from './projectdossier/state'
import { reducer as projectdossiers } from './projectdossiers/state'
import { reducer as projectmelding } from './projectmelding/state'
import { reducer as projectmeldingen } from './projectmeldingen/state'

export const reducer = {
  activiteiten,
  alerts,
  assignedBijlagen,
  auth,
  autosuggest,
  beheer,
  combiOpdracht,
  combiOpdrachten,
  contract,
  dashboard,
  entityActiviteiten,
  entityBijlagen,
  entityProjecten,
  klant,
  opdracht,
  opdrachten,
  project,
  projectdossier,
  projectdossiers,
  projecten,
  projectmelding,
  projectmeldingen,
  statusconfig,
  valuelists,
  hoofdleidingProjecten,
  hoofdleidingDashboard,
  hoofdleidingActiviteiten,
  hoofdleidingAssignedBijlagen,
  hoofdleidingProject,
}

const store = configureStore({ reducer, middleware: [thunkMiddleware] })

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState {}
}
