import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  status: 'idle',
  query: {
    filters: {
      active: true,
      processtap: null,
      netbeheerder: null,
      gebied: [],
      labels: [],
      myList: false,
      search: '',
      searchOption: null,
      fromWeek: '',
      toWeek: '',
      aannemer: null,
      typeWerk: null,
      gemeente: null,
      verantwoordelijke: null,
    },
    sort: {
      sortBy: '_id',
      sortDirection: 'desc',
    },
    page: { skip: 0, limit: 25 },
  },
}

export const createFilters = ({
  active,
  gebied,
  netbeheerder,
  processtap,
  labels,
  myList,
  search,
  searchOption,
  fromWeek,
  toWeek,
  aannemer,
  typeWerk,
  gemeente,
  verantwoordelijke,
}) => {
  const filters: IFilter[] = []

  if (active) {
    filters.push({
      name: 'isActive',
      value: true,
      operator: 'eq',
    })
  }

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })

  if (netbeheerder) {
    filters.push({
      name: 'netbeheerders',
      operator: 'eq',
      value: netbeheerder,
    })
  }

  if (processtap) {
    filters.push({
      name: 'statusText',
      value: processtap,
      operator: 'eq',
    })
  }

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({
      name: 'my-list',
      value: 'true',
      operator: 'eq',
    })
  }

  if (search && search !== '') {
    if (searchOption)
      filters.push({ name: searchOption.k, value: search, operator: 'eq' })
    else filters.push({ name: 'text', value: search, operator: 'contains' })
  }

  if (fromWeek)
    filters.push({
      name: 'startUitvoeringWeek',
      value: fromWeek,
      operator: 'gte',
    })
  if (toWeek)
    filters.push({
      name: 'startUitvoeringWeek',
      value: toWeek,
      operator: 'lte',
    })
  if (aannemer) {
    filters.push({
      name: 'aannemers',
      operator: 'eq',
      value: aannemer,
    })
  }
  if (typeWerk) {
    filters.push({
      name: 'typeWerk',
      operator: 'eq',
      value: typeWerk,
    })
  }
  if (gemeente) {
    filters.push({
      name: 'gemeente',
      operator: 'eq',
      value: gemeente,
    })
  }
  if (verantwoordelijke) {
    filters.push({
      name: 'verantwoordelijken',
      operator: 'eq',
      value: verantwoordelijke.code,
    })
  }

  return filters
}

export const fetchHoofdleidingProjecten = createAsyncThunk(
  'fetch/project/hoofdleiding/search',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/project/hoofdleiding/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van hoofdleidingen', error))
      throw error
    }
  },
)

export const updateMyList = createAsyncThunk<{ projecten: string[] }, any>(
  'update/project/hoofdleiding/my-list',
  async (
    { aanvraagID, method }: { aanvraagID: string; method: 'add' | 'remove' },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const result = await request<{ projecten: string[] }>({
        url: `/rest/my-list`,
        method: method === 'remove' ? 'DELETE' : 'POST',
        data: { id: aanvraagID },
      })

      return result
    } catch (error: any) {
      dispatch(
        apiError(
          `Fout bij ${
            method === 'remove' ? 'verwijderen van' : 'toevoegen aan'
          } mijn lijst`,
          error,
        ),
      )

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'hoofdleiding/projecten',
  initialState,
  reducers: {
    storeProject: (state, action) => {
      const { aanvraagID } = action.payload

      const items = state.data.items.map((item) => {
        if (item.aanvraagID === aanvraagID)
          return {
            ...action.payload,
            bijlagen: item.bijlagen,
          }

        return item
      })

      state.data.items = items
    },
    storeQuery: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHoofdleidingProjecten.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchHoofdleidingProjecten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchHoofdleidingProjecten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
      .addCase(updateMyList.fulfilled, (state, action) => {
        const { projecten } = action.payload

        state.data.items = state.data.items.map((item) => ({
          ...item,
          myList: projecten.includes(item.aanvraagID),
        }))
      })
  },
})

export const getHoofdleidingProjecten = (state: RootState) => ({
  data: state.hoofdleidingProjecten.data,
  isLoading: state.hoofdleidingProjecten.status === 'loading',
  query: state.hoofdleidingProjecten.query,
})

export const { reducer } = slice
export const { storeProject, storeQuery } = slice.actions
