import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import { apiError } from '../alerts/state'
import { request } from '../gateways/api'
import type { RootState } from '../store'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      gebied: [],
      netbeheerder: null,
      aannemer: null,
      discipline: null,
      search: '',
      searchOption: null,
      versieNummer: null,
      open: true,
      creatiedatum: null,
      changed: null,
      werkbak: null,
      grootverbruiklight: false,
    },
    sort: { sortBy: 'changed', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
  status: 'idle',
  werkbakken: [],
  werkbakkenFilters: {
    netbeheerder: null,
    aannemer: null,
    gebied: [],
  },
}

export const createFilters = ({
  discipline,
  netbeheerder,
  aannemer,
  gebied,
  versieNummer,
  open,
  creatiedatum,
  changed,
  search,
  searchOption,
  werkbak,
  grootverbruiklight,
}) => {
  const filters: IFilter[] = []

  if (discipline)
    filters.push({ name: 'discipline', value: discipline.code, operator: 'eq' })
  if (netbeheerder)
    filters.push({ name: 'netbeheerder', value: netbeheerder, operator: 'eq' })
  if (aannemer)
    filters.push({ name: 'aannemer', value: aannemer, operator: 'eq' })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'details.combi.gebiedscode',
      value: gebied.map((x) => x.code),
      operator: 'in',
    })
  if (versieNummer)
    filters.push({ name: 'versieNummer', value: versieNummer, operator: 'eq' })
  if (open) filters.push({ name: 'status', value: 900, operator: 'lt' })
  if (werkbak)
    filters.push({ name: 'werkbak', value: werkbak.code, operator: 'eq' })

  if (creatiedatum) {
    filters.push({
      name: 'creatiedatum',
      value: startOfDay(creatiedatum),
      operator: 'gte',
    })
    filters.push({
      name: 'creatiedatum',
      value: endOfDay(creatiedatum),
      operator: 'lte',
    })
  }

  if (changed) {
    filters.push({
      name: 'changed',
      value: startOfDay(changed),
      operator: 'gte',
    })
    filters.push({ name: 'changed', value: endOfDay(changed), operator: 'lte' })
  }

  if (search && search !== '') {
    if (searchOption)
      filters.push({ name: searchOption.k, value: search, operator: 'eq' })
    else filters.push({ name: 'text', value: search, operator: 'contains' })
  }

  if (grootverbruiklight)
    filters.push({
      name: 'details.werkProces',
      value: 'GrootverbruikLight',
      operator: 'eq',
    })

  return filters
}

export const createWerkbakkenFilters = ({ netbeheerder, aannemer, gebied }) => {
  const filters: IFilter[] = []

  if (netbeheerder)
    filters.push({ name: 'netbeheerder', value: netbeheerder, operator: 'eq' })
  if (aannemer)
    filters.push({ name: 'aannemer', value: aannemer, operator: 'eq' })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'details.combi.gebiedscode',
      value: gebied.map((x) => x.code),
      operator: 'in',
    })

  return filters
}

export const fetchOpdrachten = createAsyncThunk(
  'fetch/opdrachten',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/opdracht/deelnemer',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van opdrachten', error))

      throw error
    }
  },
)

export const fetchWerkbakken = createAsyncThunk(
  'fetch/werkbakken',
  async (payload: any, { dispatch }) => {
    try {
      const { filters } = payload

      const result = await request({
        url: '/rest/opdracht/deelnemer/werkbakken',
        method: 'POST',
        data: { filters: createWerkbakkenFilters(filters) },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van werkbakken', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'opdrachten',
  initialState,
  reducers: {
    storeQueryOpdrachten: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpdrachten.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchOpdrachten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchOpdrachten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
      .addCase(fetchWerkbakken.fulfilled, (state, action) => {
        state.werkbakken = action.payload
      })
      .addCase(fetchWerkbakken.pending, (state, action) => {
        state.werkbakkenFilters = action.meta.arg.filters
      })
      .addCase(fetchWerkbakken.rejected, (state) => {
        state.werkbakken = initialState.werkbakken
      })
  },
})

export const getOpdrachten = (state: RootState) => ({
  data: state.opdrachten.data,
  isLoading: state.opdrachten.status === 'loading',
  query: state.opdrachten.query,
})

export const getWerkbakken = (state: RootState) => state.opdrachten.werkbakken
export const getWerkbakkenFilters = (state: RootState) =>
  state.opdrachten.werkbakkenFilters

export const reducer = slice.reducer
export const { storeQueryOpdrachten } = slice.actions
