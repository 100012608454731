import React, { FormEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { EditCombiUitvoeringPerDiscipline } from '@components/EditCombiUitvoeringPerDiscipline'
import type { CombiuitvoeringPerDisciplineData } from '@models/Event'

import { getUser } from '../../../../auth/state'
import { saveEvent } from '../../state'
import type { Project } from '../../types'

interface Props {
  project: Project
  handleClose: () => void
}

export const CombiUitvoering = ({ project, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { organisatie } = useSelector(getUser)

  const disciplineVerzamelingen = project.disciplineVerzamelingen.filter(
    (dv) => dv.netbeheerderLabel === organisatie,
  )

  const [data, setData] = useState(disciplineVerzamelingen)

  const handleSaveEvent: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    const eventData: CombiuitvoeringPerDisciplineData[] = data.map((dv) => {
      if (!dv.isCombiUitvoering && dv.isCombiUitvoeringReden) {
        return {
          isCombiUitvoering: false,
          discipline: dv.disciplineLabel,
          reden: dv.isCombiUitvoeringReden,
        }
      } else {
        return {
          isCombiUitvoering: true,
          discipline: dv.disciplineLabel,
        }
      }
    })

    dispatch(
      saveEvent({
        id: project.aanvraagID,
        type: 'combiuitvoering',
        source: 'manual',
        data: {
          disciplines: eventData,
        },
      }),
    )
  }

  return (
    <form onSubmit={handleSaveEvent}>
      <DialogTitle>Mee in combi</DialogTitle>
      <DialogContent>
        <EditCombiUitvoeringPerDiscipline
          disciplineVerzamelingen={data}
          onChange={setData}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Annuleren
        </Button>

        <Button type="submit">Opslaan</Button>
      </DialogActions>
    </form>
  )
}
